@import '../pdGlobal/PdGlobal';

.full-bleed {
    padding: 0;
    @include media-breakpoint-down(sm){
        padding-top: rem(36);
    }
    &.container-fluid {
        max-width: rem(4000);
    }

    .side-by-side-tile {
        &:nth-child(1) {
            @include media-breakpoint-down(sm){
                margin-bottom: rem(40);
            }

            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(68);
                    padding-left: rem(28);

                    @include media-breakpoint-only(md){
                        padding-right: rem(28);
                        padding-left: rem(12);
                    }

                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }

            padding-right: rem(16);
            padding-left: rem(0);
            order: 1;

            @include media-breakpoint-up(lg) {
                padding-right: rem(40);
            }
        }
        @include media-breakpoint-down(sm){ 
            &:nth-child(1) {
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    padding-right: rem(28);
                    padding-left: rem(28);
                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }

            padding-right: rem(0);
            padding-left: rem(0);
            order: 1
            }
        }
        &.swapped:nth-child(1) {
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(28);
                    padding-left: rem(68);
                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }
            padding-right: rem(0);
            padding-left: rem(16);
            order: 2;

            @include media-breakpoint-up(lg) {
                padding-left: rem(40);
            }
        }
        @include media-breakpoint-down(sm){
            &.swapped:nth-child(1) {
                .side-by-side-content-CTA {
                    &.dynamic-button {
                        width: 100%;
                        padding-right: rem(28);
                        padding-left: rem(28);
                        .side-by-side-CTA {
                            width: auto;
                        }
                    }
                }
                padding-right: rem(0);
                padding-left: rem(0);
                order: 2;
            }
        }
        &:nth-child(2) {
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(28);
                    padding-left: rem(68);

                    @include media-breakpoint-only(md){
                        padding-left: rem(28);
                        padding-right: rem(12);
                    }

                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }
            padding-left: rem(16);
            padding-right: rem(0);
            order: 2;

            @include media-breakpoint-up(lg) {
                padding-left: rem(40);
            }
        }
        @include media-breakpoint-down(sm){
            &:nth-child(2) {
                .side-by-side-content-CTA {
                    &.dynamic-button {
                        width: 100%;
                        padding-right: rem(28);
                        padding-left: rem(28);
                        .side-by-side-CTA {
                            width: auto;
                        }
                    }
                }
                padding-left: rem(0);
                padding-right: rem(0);
                order: 2
            }
        }
        &.swapped:nth-child(2) {
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(68);
                    padding-left: rem(28);
                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }

            padding-left: rem(0);
            padding-right: rem(16);
            order: 1;

            @include media-breakpoint-up(lg) {
                padding-right: rem(40);
            }
        }
        @include media-breakpoint-down(sm){
            &.swapped:nth-child(2) {
                .side-by-side-content-CTA {
                    &.dynamic-button {
                        width: 100%;
                        padding-right: rem(28);
                        padding-left: rem(28);
                        .side-by-side-CTA {
                            width: auto;
                        }
                    }
                }
    
                padding-left: rem(0);
                padding-right: rem(0);
                order: 1;

            }
        }
        .side-by-side-content-CTA {

            .side-by-side-CTA {
                padding-left: rem(16);
                padding-right: rem(16);

                .side-by-side-btn {
                    padding: rem(15) 0;
                    max-width: rem(416);
                    margin: auto;

                }

                @include media-breakpoint-down(sm){
                    width: rem(220);
                    margin: auto;
                }
            }
            
            &.dynamic-button {
                padding: 0;
                position: absolute;
                &.top {
                    top: rem(24);
                }
                &.middle {
                    top: 50%;
                }
                &.bottom {
                    bottom: rem(24);
                }
                &.left {
                    text-align: left;
                }
                &.center {
                    text-align: center;
                }
                &.right{
                    text-align: right;
                }
                .side-by-side-CTA {
                    margin: 0;
                    display: inline;
                }
                
                .side-by-side-btn {
                    border: 0;
                    background-color: unset;
                    display: inline;
                    padding: 0;
                    width: max-content;
                }
            }
        }
    }
}

.no-bleed {
    padding: 0 rem(82) 0 rem(78);

    @include media-breakpoint-only(md){
        padding: 0 rem(32);
    }

    @include media-breakpoint-down(sm){
        padding: 0 rem(12) 0 rem(12);
    }
    &.container-fluid {
        max-width: rem(4000);
    }

    .side-by-side-tile {
        &:nth-child(1) {
            @include media-breakpoint-down(sm){
                margin-bottom: rem(40);
            }
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(68);
                    padding-left: rem(28);

                    @include media-breakpoint-only(md){
                        padding-right: rem(44);
                    }

                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }
            padding-right: rem(16);
            padding-left: rem(0);
            order: 1;

            @include media-breakpoint-up(lg) {
                padding-right: rem(40);
            }
            }
            @include media-breakpoint-down(sm){
                &:nth-child(1) {
                    .side-by-side-content-CTA {
                        &.dynamic-button {
                            width: 100%;
                            padding-right: rem(28);
                            padding-left: rem(28);
                            .side-by-side-CTA {
                                width: auto;
                            }
                        }
                    }
                    padding-right: rem(0);
                    padding-left: rem(0);
                    order: 1
                
                
            }
        }

        &.swapped:nth-child(1) {
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(28);
                    padding-left: rem(68);
                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }
            padding-right: rem(0);
            padding-left: rem(16);
            order: 2;

            @include media-breakpoint-up(lg) {
                padding-left: rem(40);
            }
        }
        @include media-breakpoint-down(sm){
            &.swapped:nth-child(1) {
                .side-by-side-content-CTA {
                    &.dynamic-button {
                        width: 100%;
                        padding-right: rem(28);
                        padding-left: rem(28);
                        .side-by-side-CTA {
                            width: auto;
                        }
                    }
                }
                padding-right: rem(0);
                padding-left: rem(0);
                order: 2;
                margin-bottom: 0;
            }
        }
        &:nth-child(2) {
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(28);
                    padding-left: rem(68);

                    @include media-breakpoint-only(md){
                        padding-left: rem(44);
                    }

                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }
            padding-left: rem(16);
            padding-right: rem(0);
            order: 2;

            @include media-breakpoint-up(lg) {
                padding-left: rem(40);
            }
        }
        @include media-breakpoint-down(sm){
            &:nth-child(2) {
                .side-by-side-content-CTA {
                    &.dynamic-button {
                        width: 100%;
                        padding-right: rem(28);
                        padding-left: rem(28);
                        .side-by-side-CTA {
                            width: auto;
                        }
                    }
                }
                padding-left: rem(0);
                padding-right: rem(0);
                order: 2
            }
        }
        &.swapped:nth-child(2) {
            .side-by-side-content-CTA {
                &.dynamic-button {
                    width: 100%;
                    right: 0;
                    padding-right: rem(68);
                    padding-left: rem(28);
                    .side-by-side-CTA {
                        width: auto;
                    }
                }
            }
            padding-left: rem(0);
            padding-right: rem(16);
            order: 1;

            @include media-breakpoint-up(lg) {
                padding-right: rem(40);
            }
        }
        @include media-breakpoint-down(sm){
            &.swapped:nth-child(2) {
                .side-by-side-content-CTA {
                    &.dynamic-button {
                        width: 100%;
                        padding-right: rem(28);
                        padding-left: rem(28);
                        .side-by-side-CTA {
                            width: auto;
                        }
                    }
                }
                padding-left: rem(0);
                padding-right: rem(0);
                order: 1;
            }
        }

        .side-by-side-content-CTA {

            .side-by-side-CTA {
                margin: auto;
                max-width: rem(220);
                .side-by-side-btn {
                    padding: rem(15) 0;
                }
                @include media-breakpoint-up(lg){
                    max-width: $CTAmaxWidth;
                }
                @include media-breakpoint-down(sm){
                    margin-bottom: rem(40);
                    p {
                        margin: 0;
                    }
                }
            }
            
            &.dynamic-button {
                padding: 0;
                position: absolute;
                &.top {
                    top: rem(24);
                }
                &.middle {
                    top: 50%;
                }
                &.bottom {
                    bottom: rem(24);
                    @include media-breakpoint-up(md) {
                        bottom: rem(36);
                    }
                    @include media-breakpoint-up(lg) {
                        bottom: rem(24);
                    }
                }
                &.left {
                    text-align: left;
                }
                &.center {
                    text-align: center;
                }
                &.right{
                    text-align: right;
                }
                .side-by-side-CTA {
                    margin: 0;
                    display: inline;
                }
            
                .side-by-side-btn {
                    border: 0;
                    background-color: unset;
                    display: inline;
                    padding: 0;
                    width: max-content;
                }
            }
        }
    }
}
.side-by-side-tile {
    .side-by-side-content {
        @include media-breakpoint-up(md){
            text-align: center;
            position: relative;
            height: rem(60);

            @include media-breakpoint-up(jw) {
                height: rem(128);
            }
        }

        .side-by-side-description {
            margin: auto;
            margin-top: rem(24);
            margin-bottom: rem(24);
            text-align: center;
            max-width: 80%;
            width: 100%;

            @include media-breakpoint-up(md){
                margin-top: 0;
                margin-bottom: 0;
                max-width: 100%;
            }
            @include media-breakpoint-up(lg) {
                max-width: 80%;
            }
            p {
                margin: 0;
            }

            &.left{
                margin-left: rem(16);
            }
            
            &.right{
                margin-right: rem(16);
            }
        }
    }
}
